<template>
  <v-container fluid>
    <v-dialog v-model="alertEditApplicationDialog" max-width="300">
      <v-card>
        <v-toolbar :color="HRDC_BLUE_THEME_COLOR" dark>
          Edit Application
        </v-toolbar>

        <div class="pa-4">Are you sure to edit this application ?</div>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green lighten-1"
            class="text-light"
            @click="editApplication()"
          >
            Update
          </v-btn>

          <v-btn
            color="red lighten-1"
            class="text-light"
            @click="alertEditApplicationDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div>
      <v-toolbar>
        <v-btn icon class="hidden-xs-only" @click="redirectBack()">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-toolbar-title v-if="!loadingDialog">
          <div class="d-flex align-center">
            {{ model.edit.name }}
          </div>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <span v-if="this.isHrdcSuperAdminRoles">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                class="hidden-xs-only"
                color="black"
                @click.stop="alertEditApplicationDialog = true"
              >
                <v-icon dark v-bind="attrs" v-on="on">
                  mdi-content-save
                </v-icon>
              </v-btn>
            </template>
            <span>Save</span>
          </v-tooltip>
        </span>
      </v-toolbar>
    </div>

    <v-card v-if="!loadingDialog" tile>
      <v-expansion-panels v-model="panel" multiple focusable>
        <v-row>
          <div class="col-md-6">
            <v-col>
              <!-- Company Details -->
              <v-expansion-panel>
                <v-expansion-panel-header
                  style="color: white"
                  class="expansion-details-gradient"
                >
                  <span class="text-h6">
                    COMPANY DETAILS
                    <v-icon color="white"> mdi-office-building-outline </v-icon>
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-4">
                  <v-card
                    class="pa-3"
                    :style="{ backgroundColor: HRDC_CARD_COLOR }"
                    outlined
                  >
                    <v-row no-gutters>
                      <v-col cols="4">
                        <span class="text-subtitle-2"> Company Name: </span>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                          filled
                          dense
                          v-model="companyDetails.companyName"
                          :error-messages="errorField.companyName"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row no-gutters>
                      <v-col cols="4">
                        <span class="text-subtitle-2"> Company SSM No.: </span>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                          filled
                          dense
                          v-model="companyDetails.companySsmNo"
                          :error-messages="errorField.companySsmNo"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row no-gutters>
                      <v-col cols="4">
                        <span class="text-subtitle-2"> Company Address: </span>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                          filled
                          dense
                          v-model="companyDetails.companyAddress"
                          :error-messages="errorField.companyAddress"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <hr />

                    <v-row no-gutters>
                      <v-col cols="4">
                        <span class="text-subtitle-2"> Client PIC Name: </span>
                      </v-col>
                      <v-col cols="8">
                        <v-combobox
                          filled
                          dense
                          multiple
                          small-chips
                          :items="companyDetails.picNameItems"
                          v-model="companyDetails.picName"
                          :error-messages="errorField.picName"
                        >
                          <template v-slot:selection="data">
                            <v-chip
                              small
                              :key="JSON.stringify(data.item)"
                              v-bind="data.attrs"
                              :input-value="data.selected"
                              :disabled="data.disabled"
                              @click:close="data.parent.selectItem(data.item)"
                            >
                              {{ data.item }}
                              <v-icon
                                small
                                @click="data.parent.selectItem(data.item)"
                              >
                                $delete
                              </v-icon>
                            </v-chip>
                          </template>
                          <template v-slot:append>
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-icon v-on="on"> mdi-information </v-icon>
                              </template>
                              Press <kbd>enter/tab</kbd> to insert multiple
                              email
                            </v-tooltip>
                          </template>
                        </v-combobox>
                      </v-col>
                    </v-row>

                    <v-row no-gutters>
                      <v-col cols="4">
                        <span class="text-subtitle-2"> Client PIC Email: </span>
                      </v-col>
                      <v-col cols="8">
                        <v-combobox
                          filled
                          dense
                          multiple
                          small-chips
                          :items="companyDetails.picEmailItems"
                          v-model="companyDetails.picEmail"
                          :error-messages="errorField.picEmail"
                        >
                          <template v-slot:selection="data">
                            <v-chip
                              small
                              :key="JSON.stringify(data.item)"
                              v-bind="data.attrs"
                              :input-value="data.selected"
                              :disabled="data.disabled"
                              @click:close="data.parent.selectItem(data.item)"
                            >
                              {{ data.item }}
                              <v-icon
                                small
                                @click="data.parent.selectItem(data.item)"
                              >
                                $delete
                              </v-icon>
                            </v-chip>
                          </template>
                          <template v-slot:append>
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-icon v-on="on"> mdi-information </v-icon>
                              </template>
                              Press <kbd>enter/tab</kbd> to insert multiple
                              email
                            </v-tooltip>
                          </template>
                        </v-combobox>
                      </v-col>
                    </v-row>

                    <v-row no-gutters>
                      <v-col cols="4">
                        <span class="text-subtitle-2">
                          Client PIC Contact No:
                        </span>
                      </v-col>
                      <v-col cols="8">
                        <v-combobox
                          filled
                          dense
                          multiple
                          small-chips
                          :items="companyDetails.picContactNoItems"
                          v-model="companyDetails.picContactNo"
                          :error-messages="errorField.picContactNo"
                        >
                          <template v-slot:selection="data">
                            <v-chip
                              small
                              :key="JSON.stringify(data.item)"
                              v-bind="data.attrs"
                              :input-value="data.selected"
                              :disabled="data.disabled"
                              @click:close="data.parent.selectItem(data.item)"
                            >
                              {{ data.item }}
                              <v-icon
                                small
                                @click="data.parent.selectItem(data.item)"
                              >
                                $delete
                              </v-icon>
                            </v-chip>
                          </template>
                          <template v-slot:append>
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-icon v-on="on"> mdi-information </v-icon>
                              </template>
                              Press <kbd>enter/tab</kbd> to insert multiple
                              email
                            </v-tooltip>
                          </template>
                        </v-combobox>
                      </v-col>
                    </v-row>

                    <hr />

                    <v-row no-gutters>
                      <v-col cols="4">
                        <span class="text-subtitle-2"> Bank Name: </span>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                          filled
                          dense
                          v-model="companyDetails.bankUuid"
                          :error-messages="errorField.bankUuid"
                          :disabled="paymentChannelDisabled"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row no-gutters>
                      <v-col cols="4">
                        <span class="text-subtitle-2"> Bank Account No: </span>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                          filled
                          dense
                          v-model="companyDetails.bankAccNo"
                          :error-messages="errorField.bankAccNo"
                          :disabled="paymentChannelDisabled"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row no-gutters>
                      <v-col cols="4">
                        <span class="text-subtitle-2">
                          Bank Account Holder Name:
                        </span>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                          filled
                          dense
                          v-model="companyDetails.bankAccHolderName"
                          :error-messages="errorField.bankAccHolderName"
                          :disabled="paymentChannelDisabled"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-col>
          </div>
          <div class="col-md-6">
            <v-col>
              <!-- Application Details -->
              <v-expansion-panel>
                <v-expansion-panel-header
                  style="color: white"
                  class="expansion-details-gradient"
                >
                  <span class="text-h6">
                    APPLICATION DETAILS
                    <v-icon color="white">
                      mdi-application-edit-outline
                    </v-icon>
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-4">
                  <v-card
                    class="pa-3"
                    :style="{ backgroundColor: HRDC_CARD_COLOR }"
                    outlined
                  >
                    <v-row no-gutters>
                      <v-col cols="4">
                        <span class="text-subtitle-2"> Programme Name: </span>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                          v-model="applicationDetails.programmeName"
                          :error-messages="errorField.programmeName"
                          filled
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row no-gutters>
                      <v-col cols="4">
                        <span class="text-subtitle-2"> Claim Amount : </span>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                          v-model="applicationDetails.totalPrice"
                          :error-messages="errorField.totalPrice"
                          filled
                          dense
                          hide-spin-buttons
                          type="number"
                          label="etc: 2600, 1800, ..."
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row no-gutters>
                      <v-col cols="4">
                        <span class="text-subtitle-2"> Payment Amount : </span>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                          v-model="applicationDetails.paymentAmount"
                          :error-messages="errorField.paymentAmount"
                          :disabled="paymentChannelDisabled"
                          filled
                          dense
                          hide-spin-buttons
                          type="number"
                          label="etc: 2600, 1800, ..."
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row no-gutters>
                      <v-col cols="4">
                        <span class="text-subtitle-2"> Payment Channel : </span>
                      </v-col>
                      <v-col cols="8">
                        <v-select
                          @change="changePaymentChannel()"
                          v-model="applicationDetails.paymentChannel"
                          :items="[
                            {
                              text: 'Pay & Refund',
                              value: 1,
                            },
                            {
                              text: 'Claim Basis',
                              value: 2,
                            },
                          ]"
                          dense
                          filled
                        ></v-select>
                      </v-col>
                    </v-row>

                    <v-row no-gutters v-if="!paymentChannelDisabled">
                      <v-col cols="4">
                        <span class="text-subtitle-2"> Payment Mode : </span>
                      </v-col>
                      <v-col cols="8">
                        <v-select
                          v-model="applicationDetails.paymentMode"
                          :error-messages="errorField.paymentMode"
                          :items="paymentMethodItems"
                          dense
                          filled
                        ></v-select>
                      </v-col>
                    </v-row>

                    <v-row
                      no-gutters
                      v-if="
                        !paymentChannelDisabled &&
                        applicationDetails.paymentMode == 'Other'
                      "
                    >
                      <v-col cols="4">
                        <span class="text-subtitle-2">
                          Other Payment Mode :
                        </span>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                          v-model="applicationDetails.otherPaymentMode"
                          :error-messages="errorField.otherPaymentMode"
                          dense
                          filled
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row no-gutters>
                      <v-col cols="4">
                        <span class="text-subtitle-2">
                          Screenshot of Bank Account Details for Refund:
                        </span>
                      </v-col>
                      <v-col cols="8">
                        <div
                          v-cloak
                          @drop.prevent="addDropFile($event, 'ssOfBankAcc')"
                          @dragover.prevent
                          class="w-100"
                        >
                          <v-file-input
                            :prepend-icon="null"
                            prepend-inner-icon="mdi-paperclip"
                            dense
                            filled
                            small-chips
                            v-model="applicationDetails.ssOfBankAcc"
                            :error-messages="errorField.ssOfBankAcc"
                            :truncate-length="1000"
                            :disabled="paymentChannelDisabled"
                          ></v-file-input>
                        </div>
                      </v-col>
                    </v-row>

                    <v-row no-gutters>
                      <v-col cols="4">
                        <span class="text-subtitle-2"> Event : </span>
                      </v-col>
                      <v-col cols="8">
                        <v-autocomplete
                          @change="changePaymentChannel()"
                          v-model="applicationDetails.eventUuid"
                          :items="eventItems"
                          :loading="apiGetEvents.isLoading"
                          dense
                          filled
                        ></v-autocomplete>
                      </v-col>
                    </v-row>

                    <v-row no-gutters>
                      <v-col cols="4">
                        <span class="text-subtitle-2">
                          Is Application Duplicate :
                        </span>
                      </v-col>
                      <v-col cols="8">
                        <v-select
                          @change="changePaymentChannel()"
                          v-model="applicationDetails.isApplicationDuplicate"
                          :items="[
                            {
                              text: 'Yes',
                              value: true,
                            },
                            {
                              text: 'No',
                              value: false,
                            },
                          ]"
                          dense
                          filled
                        ></v-select>
                      </v-col>
                    </v-row>

                    <hr />

                    <v-row no-gutters>
                      <v-col cols="4">
                        <span class="text-subtitle-2"> No. of Pax : </span>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                          v-model="applicationDetails.noOfPax"
                          :error-messages="errorField.noOfPax"
                          filled
                          dense
                          hide-spin-buttons
                          type="number"
                          label="Maximum 10 Pax"
                        >
                          <template v-slot:append>
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-icon v-on="on"> mdi-information </v-icon>
                              </template>
                              Change the number of pax to add more participants'
                              details
                            </v-tooltip>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>

                    <v-row
                      no-gutters
                      v-for="(pax, index) in Number(applicationDetails.noOfPax)"
                      :key="index"
                    >
                      <v-col cols="4">
                        <span class="text-subtitle-2">
                          Participant {{ pax }} Details :
                        </span>
                      </v-col>
                      <v-col cols="3" class="pr-1">
                        <v-text-field
                          ref="participantName"
                          v-model="applicationDetails.participantName[index]"
                          :error-messages="errorField.noOfPax"
                          filled
                          dense
                          :label="`Participant ${pax} Full Name`"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2" class="pr-1">
                        <v-text-field
                          ref="participantNric"
                          v-model="applicationDetails.participantNric[index]"
                          :error-messages="errorField.noOfPax"
                          filled
                          dense
                          :label="`Participant ${pax} NRIC`"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          ref="participantEmail"
                          v-model="applicationDetails.participantEmail[index]"
                          :error-messages="errorField.noOfPax"
                          filled
                          dense
                          :label="`Participant ${pax} Email`"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <hr />

                    <v-row no-gutters>
                      <v-col cols="4">
                        <span class="text-subtitle-2"> Responder Email: </span>
                      </v-col>
                      <v-col cols="8">
                        <v-autocomplete
                          v-model="applicationDetails.referByEmail"
                          :items="csDetails.salespersonData"
                          dense
                          filled
                          label="Responder Email"
                          @change="appendSalespersonName()"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>

                    <v-row no-gutters>
                      <v-col cols="4">
                        <span class="text-subtitle-2"> Responder Name: </span>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                          v-model="applicationDetails.referByName"
                          :error-messages="errorField.referByName"
                          filled
                          dense
                          disabled
                          label="Responder Name"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- Customer Service Details -->
              <v-expansion-panel>
                <v-expansion-panel-header
                  style="color: white"
                  class="expansion-details-gradient"
                >
                  <span class="text-h6">
                    CUSTOMER SERVICE DETAILS
                    <v-icon color="white"> mdi-face-agent </v-icon>
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-4">
                  <v-card
                    class="pa-3"
                    :style="{ backgroundColor: HRDC_CARD_COLOR }"
                    outlined
                  >
                    <v-row no-gutters>
                      <v-col cols="4">
                        <span class="text-subtitle-2"> Customer Service: </span>
                      </v-col>
                      <v-col cols="8">
                        <v-autocomplete
                          dense
                          filled
                          label="Customer Service"
                          v-model="csDetails.customerService"
                          :error-messages="errorField.customerService"
                          :items="csDetails.customerServiceData"
                          multiple
                          small-chips
                        >
                          <template v-slot:selection="data">
                            <v-chip
                              small
                              :key="JSON.stringify(data.item)"
                              v-bind="data.attrs"
                              :input-value="data.selected"
                              :disabled="data.disabled"
                              @click:close="data.parent.selectItem(data.item)"
                            >
                              {{ data.item.text }}
                              <v-icon
                                small
                                @click="data.parent.selectItem(data.item)"
                              >
                                $delete
                              </v-icon>
                            </v-chip>
                          </template>

                          <template v-slot:append>
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-icon v-on="on"> mdi-information </v-icon>
                              </template>
                              Press <kbd>enter/tab</kbd> to insert multiple
                              email
                            </v-tooltip>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-col>
          </div>
        </v-row>
      </v-expansion-panels>
    </v-card>

    <HrdcLoader :loadingDialog="loadingDialog"></HrdcLoader>

    <v-skeleton-loader
      v-if="loadingDialog"
      :loading="loadingDialog"
      class="mx-auto rounded-0"
      type="table-heading, card, table-heading, card, table-heading, card, table-heading, card"
    >
    </v-skeleton-loader>

    <v-dialog v-model="loadingBar" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Loading
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import Api from "@/objects/api";
import Model from "@/objects/model";
import Service from "@/objects/service";
import hrdcFunction from "@/objects/hrdcFunction";
import HrdcData from "@/services/hrdc_einvoicing/objects/globalData";

export default {
  mixins: [hrdcFunction],
  computed: {
    ...mapState({
      auth: (state) => state.auth.data,
    }),
    paymentChannelDisabled() {
      let disabled = false;
      if (this.applicationDetails.paymentChannel == 2) {
        disabled = true;
      }

      return disabled;
    },
  },
  props: ["params"],
  data: () => ({
    model: new Model(),
    service: new Service(),
    hrdcData: new HrdcData(),
    apiEditApplication: new Api(),
    apiGetApplicationDetails: new Api(),
    apiGetCsAndSalespersonList: new Api(),
    apiGetEvents: new Api(),
    allStages: [],
    loadingDialog: false,
    loadingBar: false,
    panel: [0, 1, 2, 3, 4, 5, 6, 7],
    companyDetails: {
      companyUuid: null,
      companyName: null,
      companySsmNo: null,
      companyAddress: null,
      picName: [],
      picNameItems: [],
      picEmail: [],
      picEmailItems: [],
      picContactNo: [],
      picContactNoItems: [],
      bankUuid: null,
      bankAccNo: null,
      bankAccHolderName: null,
      totalPrice: null,
      oriBankUuid: null,
      oriBankAccNo: null,
      oriBankAccHolderName: null,
    },
    errorField: {
      companyUuid: null,
      companyName: null,
      companySsmNo: null,
      companyAddress: null,
      picName: null,
      picContactNo: null,
      bankUuid: null,
      bankAccNo: null,
      bankAccHolderName: null,
      customerService: null,
      totalPrice: null,
      programmeName: null,
      paymentMode: null,
      referByName: null,
      referByEmail: null,
      eventUuid: null,
    },
    salespersonDetails: {
      salespersonName: null,
      salespersonEmail: null,
      salespersonContactNo: null,
    },
    csDetails: {
      customerService: null,
      customerServiceData: [],
      salespersonData: [],
    },
    applicationDetails: {
      paymentChannel: null,
      ssOfBankAcc: null,
      oriSsOfBankAcc: null,
      programmeName: null,
      paymentMode: null,
      otherPaymentMode: null,
      referByName: null,
      referByEmail: null,
      paymentAmount: null,
      noOfPax: null,
      isApplicationDuplicate: null,
      participantName: Array(10).fill(""),
      participantNric: Array(10).fill(""),
      participantEmail: Array(10).fill(""),
      eventUuid: null,
    },
    alertEditApplicationDialog: false,
    isPaymentMade: null,
    salespersonData: [],
    incomingData: {},
    eventItems: [],
  }),
  watch: {
    "applicationDetails.noOfPax"(newValue) {
      if (newValue > 10) {
        this.applicationDetails.noOfPax = null;
      }
    },
  },
  async created() {
    this.showLoadingDialog();
    this.model.getByKey(this.params.modelKey);
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);
    this.allStages = await this.hrdcData.allStages;
    this.rolesAssignation(this.auth.roles);
    this.serviceDataAssignation(this.model.serviceKey);

    // Assign apiDetails by mapping repository.
    const apiDetails = {
      apiGetApplicationDetails: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/get-application-details`,
        method: "post",
        params: {
          applicationUuid: this.$route.query._ausk,
        },
      },
      apiEditApplication: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/edit-application`,
        method: "post",
      },
      apiGetCsAndSalespersonList: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/get-cs-and-salesperson-list`,
        method: "post",
        params: {
          internalRoleSlugs: ["taxpod-customer-service"],
        },
      },
      apiGetEvents: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/settings/event/get-events?event_type=${this.hrdcType}`,
      },
    };

    for (const api in apiDetails) {
      if (apiDetails[api].url !== undefined) {
        this[api].setUrl(apiDetails[api].url);
      }
      if (apiDetails[api].method !== undefined) {
        this[api].setMethod(apiDetails[api].method);
      }
      if (apiDetails[api].params !== undefined) {
        this[api].setParams(apiDetails[api].params);
      }
      if (apiDetails[api].headers !== undefined) {
        this[api].setHeaders(apiDetails[api].headers);
      }
    }

    this.apiGetApplicationDetails.setCallbackCompleted((response) => {
      try {
        const { data } = response;

        if (!response.status) {
          this.$store.commit("assignDeveloperData", {
            paginationPageNo: this.$route.query.currentPageNo,
          });

          this.$router.push({
            name: "HrdcEinvoicingApplication",
            query: {
              viewId: this.$store.state.hrdc_einvoicing.data.viewId,
              tabIndex: 0,
            },
          });
          this.$store.dispatch("showMessage", {
            message: data,
            messageType: "error",
            timeout: 2000,
          });
          return;
        }

        // Store incoming request for Editted Data History purpose.
        this.storeDataHistory(data);

        // Company details.
        this.companyDetails = this.transformCompanyDetails(data, response);

        // Application Details.
        this.applicationDetails.paymentChannel = data.paymentChannel;
        this.applicationDetails.programmeName = data.programmeName;
        this.applicationDetails.paymentMode = data.paymentMode;
        this.applicationDetails.referByName = data.referByName;
        this.applicationDetails.referByEmail = data.referByEmail;
        this.applicationDetails.totalPrice = data.totalPrice;
        this.applicationDetails.paymentAmount = data.paymentAmount ?? 0;
        this.applicationDetails.noOfPax = data.noOfPax;
        this.applicationDetails.isApplicationDuplicate =
          data.isApplicationDuplicate;
        this.applicationDetails.eventUuid = data.eventUuid;

        // Assign event participants.
        for (let i = 0; i < data.ApplicationEventParticipant.length; i++) {
          this.applicationDetails.participantName[i] =
            data.ApplicationEventParticipant[i].participantName;
          this.applicationDetails.participantNric[i] =
            data.ApplicationEventParticipant[i].participantNric;
          this.applicationDetails.participantEmail[i] =
            data.ApplicationEventParticipant[i].participantEmail;
        }

        for (const item of response.attachments) {
          // Create a Blob object from the data
          const blob = new Blob([new Uint8Array(item.dataArray)]);

          // Create the File object
          const file = new File([blob], item.fileName, {
            type: `application/${item.extension}`,
          });

          if (item.type == "Screenshot of Bank Account Details for Refund") {
            this.applicationDetails.ssOfBankAcc = file;
            this.applicationDetails.oriSsOfBankAcc = file;
          }
        }

        // Salesperson details.
        this.salespersonDetails.salespersonName = data.salesperson.name ?? "-";
        this.salespersonDetails.salespersonEmail =
          data.salesperson.email ?? "-";
        this.salespersonDetails.salespersonContactNo =
          data.salesperson.mobile ?? "-";

        // Customer Service details.
        const csArrTmp = [];
        if (response.applicationCs.length > 0) {
          for (const customerService of response.applicationCs) {
            csArrTmp.push({
              text: customerService.csEmail,
              value: customerService.csUuid,
            });
          }
          this.csDetails.customerService = csArrTmp;
        }

        // Check whether company exists in vision or not.
        this.isPaymentMade = data.isPaymentMade;

        // Get CS Listing.
        this.apiGetCsAndSalespersonList.setCallbackCompleted((response) => {
          try {
            const { data, status, csData } = response;

            if (status) {
              // Handle if sso server down, just display empty items for cs lists.
              if (csData) {
                for (const item of csData) {
                  this.csDetails.customerServiceData.push({
                    text: item.email,
                    value: item.uuid,
                  });
                }
              }

              if (data) {
                this.salespersonData = data;
                for (const salespersonItem of data) {
                  this.csDetails.salespersonData.push(salespersonItem.email);
                }
              }
            }
            this.hideLoadingDialog();
          } catch (err) {
            console.log(err);
          }
        });
        this.apiGetCsAndSalespersonList.fetch();
      } catch (err) {
        console.log(err);
      }
    });
    this.apiGetApplicationDetails.fetch();

    // Get events data.
    this.apiGetEvents.setCallbackCompleted((response) => {
      try {
        const { status, data } = response;
        if (!status || data.length == 0) {
          this.errorField.eventUuid =
            "Unavailable to retrieve events, please contact admin for support";
        }

        if (status) {
          for (const item of data) {
            this.eventItems.push({
              text: this.formatEventTitle(item),
              value: item.uuid,
            });
          }
        }
      } catch (err) {
        this.errorField.eventUuid =
          "Unavailable to retrieve events, please contact admin for support";
      }
    });
    this.apiGetEvents.fetch();
  },
  methods: {
    isArrayofObjects(arr) {
      if (arr !== null) {
        return (
          arr.length > 0 &&
          typeof arr[0] === "object" &&
          arr[0] !== null &&
          !Array.isArray(arr[0])
        );
      }
    },
    changePaymentChannel() {
      this.clearErrorMsg();
      if (this.applicationDetails.paymentChannel == 1) {
        this.companyDetails.bankUuid = this.companyDetails.oriBankUuid;
        this.companyDetails.bankAccNo = this.companyDetails.oriBankAccNo;
        this.companyDetails.bankAccHolderName =
          this.companyDetails.oriBankAccHolderName;
        this.applicationDetails.ssOfBankAcc =
          this.applicationDetails.oriSsOfBankAcc;
      } else {
        this.companyDetails.bankUuid = null;
        this.companyDetails.bankAccNo = null;
        this.companyDetails.bankAccHolderName = null;
        this.applicationDetails.ssOfBankAcc = null;
      }
    },
    clearErrorMsg() {
      for (let error in this.errorField) {
        this.errorField[error] = null;
      }
    },
    showErrorMessage(response) {
      for (const key in response.data) {
        this.errorField[key] = response.data[key];
      }
    },
    // Create function to capture original company data.
    transformCompanyDetails(data, response) {
      return {
        companyUuid: data.Company[0].uuid,
        companyName: `${data.oriCompanyName}`,
        companySsmNo: data.Company[0].oldSsmNo,
        companyAddress: data.Company[0].oldAddress,
        picName: response.companyPicData
          .map((item) => item.picName)
          .filter((item) => item !== null),
        picNameItems: response.companyPicData
          .map((item) => item.picName)
          .filter((item) => item !== null),
        picEmail: response.companyPicData
          .map((item) => item.picEmail)
          .filter((item) => item !== null),
        picEmailItems: response.companyPicData
          .map((item) => item.picEmail)
          .filter((item) => item !== null),
        picContactNo: response.companyPicData
          .map((item) => item.picContactNo)
          .filter((item) => item !== null),
        picContactNoItems: response.companyPicData
          .map((item) => item.picContactNo)
          .filter((item) => item !== null),
        bankUuid: data.bankUuid ?? null,
        bankAccNo:
          data.oldBankAccount == null || data.oldBankAccount == "null"
            ? null
            : data.oldBankAccount.replace(/\s+/g, ""),
        bankAccHolderName:
          data.oldBankAccountHolderName == null || data.oldBankAccount == "null"
            ? null
            : data.oldBankAccountHolderName,
        oriBankUuid: data.bankUuid ?? null,
        oriBankAccNo:
          data.oldBankAccount == null || data.oldBankAccount == "null"
            ? null
            : data.oldBankAccount.replace(/\s+/g, ""),
        oriBankAccHolderName:
          data.oldBankAccountHolderName == null || data.oldBankAccount == "null"
            ? null
            : data.oldBankAccountHolderName,
        totalPrice: data.totalPrice,
      };
    },
    checkRoleAllows(checkRole) {
      return this.auth.roles.some((role) => role.slug.includes(checkRole));
    },
    appendSalespersonName() {
      // Get the salesperson name.
      const salespersonObj = this.salespersonData.find(
        (item) => item.email == this.applicationDetails.referByEmail
      );

      this.applicationDetails.referByName = salespersonObj.name;
    },
    redirectBack() {
      let route = "HrdcEinvoicingApplication";
      if (this.params.backTo) {
        route = this.params.backTo;
      }

      this.$store.commit("assignDeveloperData", {
        paginationPageNo: this.$route.query.currentPageNo,
      });

      this.$router.push({
        name: route,
        params: {
          modelKey: this.model.key,
          backTo: "",
        },
        query: {
          viewId: this.$store.state.hrdc_einvoicing.data.viewId,
          _ausk: this.$route.query._ausk,
        },
      });
    },
    checkEndingComma(str) {
      return str.endsWith(",") ? str.slice(0, -1) : str;
    },
    storeDataHistory(data) {
      let picName = this.checkEndingComma(
        data.ApplicationPicDetail.map((item) => item.picName).join(",")
      );
      let picEmail = this.checkEndingComma(
        data.ApplicationPicDetail.map((item) => item.picEmail).join(",")
      );
      let picContactNo = this.checkEndingComma(
        data.ApplicationPicDetail.map((item) => item.picContactNo).join(",")
      );

      this.incomingData = {
        companySsmNo: [data.Company[0].oldSsmNo],
        companyName: [data.Company[0].oldCompanyName],
        companyAddress: [data.Company[0].oldAddress],
        picName: [picName],
        picEmail: [picEmail],
        picContactNo: [picContactNo],
        bankName: [data.bankUuid],
        bankAccount: [data.oldBankAccount],
        bankAccountHolderName: [data.oldBankAccountHolderName],
        customerService: [
          data.ApplicationCustomerService.filter(
            (item) => item.csName && item.csName !== ""
          )
            .map((item) => item.csName)
            .join(","),
        ],
        totalPrice: [data.totalPrice],
        paymentAmount: [data.paymentAmount],
        programmeName: [data.programmeName],
        referByName: [data.referByName],
        referByEmail: [data.referByEmail],
        noOfPax: [data.noOfPax],
        participantName: [
          data.ApplicationEventParticipant.map(
            (item) => item.participantName
          ).join(","),
        ],
        participantNric: [
          data.ApplicationEventParticipant.map(
            (item) => item.participantNric
          ).join(","),
        ],
        participantEmail: [
          data.ApplicationEventParticipant.map(
            (item) => item.participantEmail
          ).join(","),
        ],
        paymentChannel: [data.paymentChannel],
        paymentMode: [data.paymentMode],
        isApplicationDuplicate: [data.isApplicationDuplicate],
        eventUuid: [data.eventUuid],
      };
    },
    editApplication() {
      this.loadingBar = !this.loadingBar;

      if (this.isArrayofObjects(this.csDetails.customerService)) {
        this.csDetails.customerService = this.csDetails.customerService.map(
          (item) => item.value
        );
      }

      // Clear bank & screenshot if Claim Basis.
      if (this.applicationDetails.paymentChannel == 2) {
        this.companyDetails.bankUuid = null;
        this.companyDetails.bankAccNo = null;
        this.companyDetails.bankAccHolderName = null;
      }

      const fd = new FormData();
      fd.append("userUuid", this.auth.uuid);
      fd.append("applicationUuid", this.$route.query._ausk);
      fd.append("companyUuid", this.companyDetails.companyUuid);
      fd.append("companySsmNo", this.companyDetails.companySsmNo);
      fd.append("companyName", this.companyDetails.companyName);
      fd.append("companyAddress", this.companyDetails.companyAddress);
      fd.append("picName", this.companyDetails.picName.join(","));
      fd.append("picEmail", this.companyDetails.picEmail.join(","));
      fd.append("picContactNo", this.companyDetails.picContactNo.join(","));
      fd.append("bankUuid", this.companyDetails.bankUuid);
      fd.append("bankAccNo", this.companyDetails.bankAccNo);
      fd.append("bankAccHolderName", this.companyDetails.bankAccHolderName);
      fd.append("customerService", this.csDetails.customerService);
      fd.append("totalPrice", this.applicationDetails.totalPrice);
      fd.append("paymentAmount", this.applicationDetails.paymentAmount);
      fd.append("paymentChannel", this.applicationDetails.paymentChannel);
      fd.append("ssOfBankAcc", this.applicationDetails.ssOfBankAcc);
      fd.append("programmeName", this.applicationDetails.programmeName);
      fd.append("referByName", this.applicationDetails.referByName);
      fd.append("referByEmail", this.applicationDetails.referByEmail);
      fd.append("noOfPax", this.applicationDetails.noOfPax);
      fd.append(
        "participantName",
        this.applicationDetails.participantName
          .filter((name) => name !== "")
          .slice(0, this.applicationDetails.noOfPax)
      );
      fd.append(
        "participantNric",
        this.applicationDetails.participantNric
          .filter((nric) => nric !== "")
          .slice(0, this.applicationDetails.noOfPax)
      );
      fd.append(
        "participantEmail",
        this.applicationDetails.participantEmail
          .filter((email) => email !== "")
          .slice(0, this.applicationDetails.noOfPax)
      );

      let paymentMode = this.applicationDetails.paymentMode;
      if (paymentMode == "Other") {
        paymentMode = this.applicationDetails.otherPaymentMode;
      }

      fd.append("paymentMode", paymentMode);
      fd.append(
        "isApplicationDuplicate",
        this.applicationDetails.isApplicationDuplicate
      );
      fd.append("eventUuid", this.applicationDetails.eventUuid);
      fd.append("incomingData", JSON.stringify(this.incomingData));

      this.apiEditApplication.setParams(fd);
      this.apiEditApplication.setCallbackCompleted((response) => {
        try {
          this.loadingBar = !this.loadingBar;
          this.clearErrorMsg();
          if (!response.status) {
            this.showErrorMessage(response);
            this.$store.dispatch("showMessage", {
              message: "Please fill-in all the fields",
              messageType: "error",
              timeout: 2000,
            });
          }

          if (response.status) {
            let route = "HrdcEinvoicingApplication";
            if (this.params.backTo) {
              route = this.params.backTo;
            }

            this.$store.commit("assignDeveloperData", {
              paginationPageNo: this.$route.query.currentPageNo,
            });

            this.$router.push({
              name: route,
              params: {
                modelKey: this.model.key,
                backTo: "",
              },
              query: {
                viewId: this.$store.state.hrdc_einvoicing.data.viewId,
                _ausk: this.$route.query._ausk,
              },
            });

            this.$store.dispatch("showMessage", {
              message: response.data,
              messageType: "success",
              timeout: 2000,
            });
            return;
          }
          this.loadingBar = !this.loadingBar;
        } catch (err) {
          console.log(err);
        }
      });
      this.apiEditApplication.fetch();

      this.loadingBar = !this.loadingBar;
      this.alertEditApplicationDialog = !this.alertEditApplicationDialog;
    },
  },
};
</script>

<style scope>
.v-tabs-bar.v-tabs-bar--is-mobile .v-tab,
.v-tabs-bar .v-tab {
  margin-left: 0px !important;
}
.v-messages__message {
  color: red;
}

.expansion-details-gradient {
  background-color: rgba(6, 48, 88, 1);
}

.v-expansion-panel-content__wrap {
  padding: 0 10px 16px;
}

.theme--light.v-expansion-panels.v-expansion-panels--focusable
  .v-expansion-panel-header--active::before {
  opacity: 0;
}
</style>
